<template>
    <div class="page--blog">
        <section>
            <div class="container container-l mx-auto">
                <router-link :to="`/blog/${firstArticle.slug}`">
                    <div class="first-article">
                        <figure class="first-article-img">
                            <!-- <img
								class="object-cover"
								:src="firstArticle.media[0].path"
								alt=""
							/> -->
                            <img
                                class="object-cover"
                                src="@/assets/images/recent-work.png"
                                alt=""
                            />
                        </figure>
                        <div class="first-article-head">
                            <p class="title">
                                {{ firstArticle.text }}
                            </p>
                            <p class="date">22/10/2012</p>
                        </div>
                    </div>
                </router-link>
            </div>
        </section>
        <section>
            <div class="all-news">
                <blog-section
                    :title="$t('headings.allNews')"
                    :aticles="blogArticlesShift"
                />
            </div>
        </section>
    </div>
</template>
<script>
import BlogSection from "../components/BlogSection.vue";
export default {
    data () {
        return {
            blogArticles: [
                {
                    id: 1,
                    text: "how to How to plan your next project",
                    media: [{ path: "https://via.placeholder.com/300" }],
                    slug: "245879"
                },
                {
                    id: 2,
                    text: "blog article test 2",
                    media: [{ path: "@/assets/images/work-card-1.png" }],
                    slug: "2467879"
                },
                {
                    id: 3,
                    text: "blog article test 3",
                    media: [{ path: "@/assets/images/work-card-1.png" }],
                    slug: "243379"
                },
                {
                    id: 4,
                    text: "blog article test 4",
                    media: [{ path: "@/assets/images/work-card-1.png" }],
                    slug: "245479"
                },
                {
                    id: 5,
                    text: "blog article test 5",
                    media: [{ path: "@/assets/images/work-card-1.png" }],
                    slug: "245579"
                },
                {
                    id: 6,
                    text: "blog article test 6",
                    media: [{ path: "@/assets/images/work-card-1.png" }],
                    slug: "245679"
                },
                {
                    id: 7,
                    text: "blog article test 7",
                    media: [{ path: "@/assets/images/work-card-1.png" }],
                    slug: "245779"
                },
                {
                    id: 8,
                    text: "blog article test 8",
                    media: [{ path: "@/assets/images/work-card-1.png" }],
                    slug: "245879"
                },
                {
                    id: 9,
                    text: "blog article test 9",
                    media: [{ path: "@/assets/images/work-card-1.png" }],
                    slug: "245979"
                }
            ],
            firstArticle: null,
            blogArticlesShift: []
        };
    },
    components: {
        BlogSection
    },
    mounted () {
        // console.log(this.blogArticles);
        this.firstArticle = this.blogArticles.shift();
        this.blogArticlesShift = this.blogArticles;
        // console.log(this.blogArticles);
        // console.log(this.firstArticle);
    }
};
</script>
