<template>
	<div>
		<div
			class="blog-card"
			@mouseover="isHoverd = true"
			@mouseleave="isHoverd = false"
		>
			<router-link :to="`/blog/${slug}`">
				<figure class="blog-card-img">
					<!-- <img :src="imgPath" alt="" /> -->
					<img src="@/assets/images/blog1.png" alt="" />
				</figure>
				<p class="blog-card-text">{{ text }}</p>
			</router-link>
		</div>
	</div>
</template>
<script>
export default {
	props: ["imgPath", "text", "slug"],
	data() {
		return {
			isHoverd: false
		};
	}
};
</script>
