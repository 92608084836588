<template>
    <div class="">
        <div class="blog-section container-l">
            <div class="blog-section-header">
                <h2 class="title">{{ title }}</h2>
                <p
                    v-if="withDesc"
                    class="description"
                >
                    {{ desc }}
                </p>
            </div>
            <div class="blog-section-body">
                <blog-card
                    v-for="article in articless"
                    :key="article.id"
                    class="blog-card-component"
                    :text="article.text"
                    :img-path="article.media[0].path"
                    :slug="article.slug"
                />
                <!-- <blog-card
					class="blog-card-component"
					:text="
						'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus'
					"
					:img-path="'@/assets/images/work-card-1.png'"
					:slug="slug"
				/>
				<blog-card
					class="blog-card-component"
					:text="
						'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus'
					"
					:img-path="'@/assets/images/work-card-1.png'"
					:slug="slug"
				/>
				<blog-card
					class="blog-card-component"
					:text="
						'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus'
					"
					:img-path="'@/assets/images/work-card-1.png'"
					:slug="slug"
				/>
				<blog-card
					class="blog-card-component"
					:text="
						'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus'
					"
					:img-path="'@/assets/images/work-card-1.png'"
					:slug="slug"
				/>
				<blog-card
					class="blog-card-component"
					:text="
						'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus'
					"
					:img-path="'@/assets/images/work-card-1.png'"
					:slug="slug"
				/>
				<blog-card
					class="blog-card-component"
					:text="
						'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus'
					"
					:img-path="'@/assets/images/work-card-1.png'"
					:slug="slug"
				/>
				<blog-card
					class="blog-card-component"
					:text="
						'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus'
					"
					:img-path="'@/assets/images/work-card-1.png'"
					:slug="slug"
				/>
				<blog-card
					class="blog-card-component"
					:text="
						'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus'
					"
					:img-path="'@/assets/images/work-card-1.png'"
					:slug="slug"
				/> -->
            </div>
        </div>
    </div>
</template>
<script>
import BlogCard from "../components/BlogCard.vue";
export default {
    props: ["aticles", "withDesc", "desc", "title"],
    components: {
        BlogCard
    },
    data () {
        return {
            slug: "245879",
            articless: null
        };
    },
    mounted () {
        this.articless = this.aticles;
        // console.log(this.aticles);
    }
};
</script>
